<template>
  <div class="member_main_area">
    <div class="manager_main_container">
      <div class="manager_nav_container">
        <div class="manager_nav_logo">
          <a target="_blank">
            <img src="../../assets/image/member_login_logo.png" alt />
          </a>
        </div>
        <div class="member_nav_btn_open" id="btnOpen" @click="toggleNavlist" role="button">會員資訊</div>
        <div class="manager_nav_account_container">
          <div class="manager_nav_account">
            <div class="manager_nav_headpic">
              <div>
                <img :src="userBaseInfo.photoUrl?userBaseInfo.photoUrl:defaultImg" alt />
              </div>
            </div>
            <div class="manager_nav_infor">
              <div>
                <span>{{userBaseInfo.fullName}}</span>&nbsp;
                <span>{{userBaseInfo.userType}}</span>
              </div>
              <div>
                <span class="manager_reset_passowrd_btn" @click="openBaseDialog(1)">修改密碼</span>
                <img src="../../assets/image/yellow_line.jpg" class="yellow_line" alt />
                <span class="manager_logout_btn">
                  <a @click="loginOut">帳號登出</a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- 會員資訊 -->
        <!-- 會員資訊end -->
      </div>
      <div class="manager_lesson_infor">
        <div class="manager_lesson_tip_container">
          <div class="manager_lesson_tip manager_lesson_date_tip" @click="openTopDialog(1)">
            尚未填上課資料<span class="manager_notice">({{noticeCounts}})
            </span>
          </div>
          <div class="manager_lesson_tip manager_lesson_date_tip" @click="openTopDialog(2)">
            上課20分鐘未打卡<span class="manager_notice">
              ({{signCounts}})
            </span>
          </div>
        </div>
        <!-- <div class="manager_lesson_tip_container" @click="openTopDialog"></div> -->
        <router-view />
      </div>
    </div>
    <!---<common-temp ref="commonNews" :topNavId.sync="topNavDialogId" :userInfo="noticeData"></common-temp>
    -->
    <div class="system_talk_area" style>
      <img src="../../assets/image/system_talk_close.png" class="system_talk_close" alt />
      <div class="system_talk_window">
        <img src="https://qr-official.line.me/sid/M/093jpnhk.png" />
      </div>
    </div>
    <!-- ... 修改密码 ... -->
    <div class="pup_setting_class" v-if="changeUser==1">
      <div class="pup_manager_bg" @click="openBaseDialog(0)"></div>
      <div class="pup_manager_window">
        <div style="padding-bottom:80px;">
          <div class="pup_manager_container">
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">原始密碼</div>
              <div class="pup_manager_input">
                <input type="password" v-model="basePass.old_password" />
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">修改密碼</div>
              <div class="pup_manager_input">
                <input type="password" v-model="basePass.new_password" />
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">確認密碼</div>
              <div class="pup_manager_input">
                <input type="password" v-model="basePass.sure_new_password" />
              </div>
            </div>
            <div class="form_input_tip" style="margin:6px 30px" v-if="errTips">{{errTips}}</div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openBaseDialog(0)"
            class="pup_manager_close"
            alt
          />
          <div class="pup_manager_window_btn" @click="changePass">修改密碼</div>
        </div>
      </div>
    </div>
    <!--密码修改成功-->

    <!-- ... pup視窗-未下課打卡老师資料名單 ... -->
    <div class="pup_manager_notice" v-if="noticeTipsOpen===2">
      <div class="pup_manager_bg" @click="toggleManagerNotice(0)"></div>
      <div class="pup_manager_window">
        <div>
          <div class="pup_manager_container">
            <div class="pup_notice_title">上課15分鐘后教師未打卡：</div>
            <div class="pup_notice_text" v-for="(log,k) in teacherNoSignList" :key="k">
              <!-- <span>{{log.studentName}}&nbsp;{{log.classDate}}&nbsp;14:00-15:00&nbsp;物理</span> -->
              <span>{{log.log}}</span>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="toggleManagerNotice(0)"
            class="pup_manager_close"
            alt
          />
        </div>
      </div>
    </div>
    <!-- ... pup視窗-未下課打卡老师資料名單end ... -->
    <!-- ... pup視窗-未填寫上課資料名單 ... -->
    <div class="pup_manager_notice" v-if="noticeTipsOpen===1">
      <div class="pup_manager_bg" @click="toggleManagerNotice(0)"></div>
      <div class="pup_manager_window">
        <div>
          <div class="pup_manager_container">
            <div class="pup_notice_title">尚未填寫上課記錄：</div>
            <div class="pup_notice_text" v-for="(log,k) in classCheckEditList" :key="k">
              <!-- <span>{{log.studentName}}&nbsp;{{log.classDate}}&nbsp;14:00-15:00&nbsp;物理</span> -->
              <span>{{log.log}}</span>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="toggleManagerNotice(0)"
            class="pup_manager_close"
            alt
          />
        </div>
      </div>
    </div>
    <!-- ... pup視窗-未填寫上課資料名單end ... -->
  </div>
</template>
<script>
import {
  getStaffBaseInfo,
  getStaffLoginout,
  getStaffPasswordmod,
  getHsdNoticesType,
  getTeacherDisSignIn,
  getCheckEditList,
  // getClassByDay,
} from "../../api/api";
import { mapState, mapMutations, mapActions } from "vuex";
import commonTemp from "../childComponent/common_news_temp";
import defaultImg from "@/assets/image/member_headpic.jpg";
export default {
  data() {
    return {
      defaultImg,
      isNewNoticeType: false,
      noticeCounts: 0,
      signCounts: 0,
      teacherNoSignList: [],
      signTipsOpen: false,
      errTips: "",
      changeUser: 0,
      noticeData: { userId: "", noticeType: "3" },
      topNavDialogId: 0,
      userBaseInfo: {},
      basePass: { old_password: "", new_password: "", sure_new_password: "" },
      passRules: {
        // old_password: [
        //   { required: true, message: "請輸入設定密碼", trigger: "blur" }
        // ],
        // new_password: [
        //   { required: true, message: "請再次輸入設定密碼", trigger: "blur" }
        // ],
        // sure_new_password: [
        //   { required: true, message: "請再次輸入設定密碼", trigger: "blur" }
        // ]
      },
    };
  },
  components: { commonTemp },
  created() {
    // this.getOrderByDay()
    this.getBaseInfo();
    this.hGetClassCheckEditl();
    this.getTeacherNoSign();
    this.noticeCounts = this.classCheckEditList
      ? this.classCheckEditList.length
      : 0;
  },
  computed: {
    ...mapState(["noticeTipsOpen", "classCheckEditList"]),
  },
  methods: {
    ...mapMutations(["toggleManagerNotice", "setManagerNotice"]),
    async getOrderByDay() {
      const res = await getClassByDay();
    },
    //未打卡
    async getTeacherNoSign() {
      const res = await getTeacherDisSignIn();
      if (res.success == 1) {
        this.teacherNoSignList = res.data;
        this.signCounts = res.data ? res.data.length : 0;
      }
    },
    //未填资料
    async hGetClassCheckEditl() {
      const res = await getCheckEditList();
      if (res.success == 1) {
        this.setManagerNotice(res.data);
      }
    },
    //导航栏显示与隐藏
    toggleNavlist() {
      let content = document.getElementById("showNavCont"),
        btn = document.getElementById("btnOpen");
      let display;
      if (content.currentStyle) {
        display = content.currentStyle.display;
      } else {
        display = window.getComputedStyle(content, false).display;
      }
      if (display == "block") {
        content.classList.remove("nav-content");
        btn.classList.remove("active");
      } else {
        content.classList.add("nav-content");
        btn.classList.add("active");
      }
    },
    //最新消息弹窗
    showNewsDialog(id) {
      this.topNavDialogId = id;
      if (id == 1) {
        this.isNewNoticeType = false;
        this.$refs["commonNews"].getNoticeList();
      }
    },
    //未填资料
    openTopDialog(id) {
      if (
        (id == 1 && this.noticeCounts == 0) ||
        (id == 2 && this.signCounts == 0)
      ) {
        return;
      }
      this.toggleManagerNotice(id);
    },
    //返回首页
    goHome() {
      this.$router.push("/index");
    },
    //登出
    async loginOut() {
      const res = await getStaffLoginout();
      if (res && res.code == 200) {
        this.$router.push("/managerLogin");
      }
    },
    //客服基本信息
    async getBaseInfo() {
      const res = await getStaffBaseInfo();
      if (res && res.success == 1) {
        this.userBaseInfo = res.data;
        this.userBaseInfo.photoUrl = `data:image/png;base64,${res.data.photoUrl}`;
        this.noticeData.userId = this.userBaseInfo.userId;
        this.getNoticeType();
      }
    },
    //消息是否已读
    async getNoticeType() {
      const res = await getHsdNoticesType({
        noticeType: "3",
        userId: this.userBaseInfo.userId,
      });
      if (res && res.success == 1) {
        this.isNewNoticeType = res.data;
      }
    },
    //头像上传
    sendUserAvatar() {},
    //修改密码
    openBaseDialog(id) {
      this.changeUser = id;
    },
    //修改密碼
    changePass() {
      if (this.basePass.sure_new_password !== this.basePass.new_password) {
        this.errTips = "兩次輸入設定密碼不一致";
        return;
      }
      this.errTips = null;
      let d = {
        old_password: this.basePass.old_password,
        new_password: this.basePass.new_password,
      };
      this.fetUpdatepass(d);
    },
    async fetUpdatepass(d) {
      const res = await getStaffPasswordmod(d);
      if (res && res.code == 200) {
        this.changeUser = 2;
        setTimeout(() => {
          this.$router.push("/managerLogin");
        }, 3000);
      } else {
        if (res && res.code == 110705) {
          this.errTips = "原始密碼輸入錯誤";
        }
      }
    },
  },
};
</script>
<style>
@import "../../assets/css/manager_center.css";
.noDataTab {
  line-height: 60px;
  width: 100%;
  text-align: center;
  color: #909399;
  font-size: 14px;
}
.nav-content {
  display: block;
}
@media (max-width: 500px) {
  .nav-content {
    display: block;
  }
}
</style>